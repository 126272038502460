<template>
    <ListStatesCities></ListStatesCities>
</template>
  
  <script>

import ListStatesCities from "@core/layouts/components/admin-components/ListStatesCities.vue";

export default {
    name: "StatesCities",

    components: {
        ListStatesCities,
    }
}
</script>
  
  <style scoped>
  </style>